import React from "react";
import "./App.css";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import About from "./Components/About";
import Therapy from "./Components/Therapy";
import Contact from "./Components/Contact";
import Offer from "./Components/Offer";
import OnlineTherapy from "./Components/OnlineTherapy";
import axios from 'axios'

const App = (props) => {

  const [appState, setAppState] = React.useState({
    content: {}
  })

  React.useEffect(() => {
    axios.get('./content.json')
      .then(r => {
        setAppState((prev) => { return { ...prev, content: r.data } })
      })
      .catch()
  }, [props.testMode])


  return (
    <div className="App">
      <Header data={appState.content.main} />
      <About data={appState.content.aboutMe} />
      <Offer data={appState.content.offer} />
      <Therapy data={appState.content.aboutTherapy} />
      <OnlineTherapy data={appState.content.onlineTherapy} />
      <Contact data={appState.content.contact} />
      <Footer data={appState.content.footer} version={props.version} sha={props.sha} />
    </div>
  );
}

export default App;
