import React, { useRef, useState } from "react";
import { Fade } from "react-reveal";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import fakeAwaiter from "../helpers/fakeAwaiter";
import FeedbackBox from "./FeedbackBox";

const Contact = (props) => {
  const captchaRef = useRef();
  const [formState, setFormState] = useState({
    sending: false,
    showFeedback: false,
    success: false,
    invalid: false
  });
  const [contactForm, setContactForm] = useState({
    email: "",
    name: "",
    message: "",
  });

  const isFormInvalid = () => {
    return contactForm.email === "" || contactForm.name === "" || contactForm.message === ""
  }

  const submitContactForm = async (e) => {
    e.preventDefault();

    if (isFormInvalid()) {
      setFormState((prev) => {
        return { ...prev, showFeedback: true, success: false, invalid: true}
      })

      setTimeout(() => {
        setFormState((prev) => {
          return {
            ...prev,
            showFeedback: false,
          };
        });
      }, 3000);
      return;
    }

    setFormState((prev) => {
      return { ...prev, sending: true, showFeedback: false, invalid: false };
    });

    const [response] = await Promise.all([
      captchaRef.current.executeAsync().then((captchaToken) => {
        const payload = { ...contactForm, captchaToken };
        return axios.post(`https://justynajanus.com/contact.php`, payload);
      }),
      fakeAwaiter(1500),
    ]);

    const formSucceed = response.data.success;
    if (response.data.success) {
      console.log(response.data);
    }

    setContactForm({
      email: "",
      name: "",
      message: "",
    });
    setFormState((prev) => {
      return {
        ...prev,
        sending: false,
        showFeedback: true,
        success: formSucceed,
      };
    });

    setTimeout(() => {
      setFormState((prev) => {
        return {
          ...prev,
          showFeedback: false,
        };
      });
    }, 3000);
  };

  if (!props.data) return null;

  const { labels, contactData } = props.data;

  const renderFeedbackForm = () => {
    return formState.success ? (
      <FeedbackBox success={formState.success} show={formState.showFeedback}>
        <i className="fa fa-check"></i> {labels.formSuccess}
      </FeedbackBox>
    ) : formState.invalid ? 
    (
      <FeedbackBox success={formState.success} show={formState.showFeedback}>
        {labels.formError3}
      </FeedbackBox>
    )
    : (
      <FeedbackBox success={formState.success} show={formState.showFeedback}>
        {labels.formError1} <br /> {labels.formError2}
      </FeedbackBox>
    );
  };

  return (
    <section id="contact" className="green">
      <Fade bottom duration={1000}>
        <div className="row section-head">
          <div className="twelve columns">
            <div className="contact-data">
              <h2>
                <span className="fa fa-phone bigger"></span>{" "}
                <span className="bigger">{contactData.phone}</span>
              </h2>
              <h2>
                <a id="email" href={`mailto:${contactData.email}`}>
                  <span className="fa fa-envelope big"></span>{" "}
                  <span className="big">{contactData.email}</span>
                </a>
              </h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div
            className="twelve columns main-col"
            style={{ textAlign: "center" }}
          >
            <Fade left opposite>
              <h2 className="contact-data">Formularz kontaktowy</h2>
              <form
                method="post"
                name="contactForm"
                onSubmit={submitContactForm}
              >
                <fieldset>
                  <div>
                    <label htmlFor="contactName">
                      {labels.name} <span className="required">*</span>
                    </label>
                    <input
                      value={contactForm.name}
                      type="text"
                      size="35"
                      onChange={(e) => {
                        setContactForm({
                          ...contactForm,
                          name: e.target.value,
                        });
                      }}
                    />
                  </div>

                  <div>
                    <label htmlFor="contactEmail">
                      {labels.email} <span className="required">*</span>
                    </label>
                    <input
                      value={contactForm.email}
                      type="email"
                      size="35"
                      onChange={(e) => {
                        setContactForm({
                          ...contactForm,
                          email: e.target.value,
                        });
                      }}
                    />
                  </div>

                  <div>
                    <label htmlFor="contactMessage">
                      {labels.message} <span className="required">*</span>
                    </label>
                    <textarea
                      value={contactForm.message}
                      cols="50"
                      rows="7"
                      onChange={(e) => {
                        setContactForm({
                          ...contactForm,
                          message: e.target.value,
                        });
                      }}
                    ></textarea>
                  </div>
                  <div>
                    <div className="recaptcha-terms">
                      <span>
                        Formularz chroniony przez reCAPTCHA. Zastosowanie ma{" "}
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://policies.google.com/privacy"
                        >
                          Polityka Prywatności
                        </a>{" "}
                        i{"\u00a0"}
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://policies.google.com/terms"
                        >
                          Warunki Usługi
                        </a>{" "}
                        Google.
                      </span>
                    </div>
                    <ReCAPTCHA
                      ref={captchaRef}
                      sitekey="6LeDfDscAAAAAB1Gsukl1Alc7uOKiyx_YNupdjuy"
                      size="invisible"
                      onChange={(val) => {
                        console.log(val);
                      }}
                    />
                  </div>

                  <div className="submit-area">
                    {formState.sending ? (
                      <img
                        className="submit-loader"
                        alt="Sending"
                        src="images/loader.gif"
                      />
                    ) : (
                      <button type="submit" className="submit">
                        {labels.submit}
                      </button>
                    )}
                  </div>
                  <div>{renderFeedbackForm()}</div>
                </fieldset>
              </form>
            </Fade>
          </div>
        </div>
      </Fade>
    </section>
  );
};

export default Contact;
