import React from "react";
import Fade from "react-reveal";

const Header = (props) => {
  if (!props.data) return null;

  const { quote, nav } = props.data;

  return (
    <header id="home">
      <nav id="nav-wrap">
        <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
          Show navigation
        </a>
        <a className="mobile-btn" href="#home" title="Hide navigation">
          Hide navigation
        </a>

        <ul id="nav" className="nav">
          <li className="current flex-first">
            <a className="smoothscroll logo" href="#home">
              <img alt={nav.main} src="/images/logo.svg" />
            </a>
          </li>

          <li>
            <a className="smoothscroll" href="#about-me">
              {nav.aboutMe}
            </a>
          </li>

          <li>
            <a className="smoothscroll" href="#offer">
              {nav.offer}
            </a>
          </li>

          <li>
            <a className="smoothscroll" href="#therapy">
              {nav.aboutTherapy}
            </a>
          </li>

          <li>
            <a className="smoothscroll" href="#online-therapy">
              {nav.onlineTherapy}
            </a>
          </li>

          <li>
            <a className="smoothscroll" href="#contact">
              {nav.contact}
            </a>
          </li>
        </ul>
      </nav>

      <div className="row banner">
        <div className="banner-text">
          <Fade bottom>
            <aside>
              <blockquote>
                <p>{quote.text}</p>
              </blockquote>
              <figcaption>— {quote.author}</figcaption>
            </aside>
          </Fade>
        </div>
      </div>

      <p className="scrolldown">
        <a className="smoothscroll circle-button" href="#about-me">
          <i className="icon-down-circle"></i>
        </a>
      </p>
    </header>
  );
};

export default Header;
