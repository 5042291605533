import * as React from "react";
import Fade from "react-reveal";
import writeDescription from "../helpers/writeDescription";

const writeSteps = (steps) => {
    if (Array.isArray(steps)) {
      return (<div>{steps.map((s, idx) => (<p key={idx} className="justify"><strong>{s.title}</strong><br />{s.description}</p>))}</div>)
    }
  
    return (<p>{steps}</p>)
  }

const OnlineTherapy = (props) => {
  if (!props.data) return null;

  const { title, description, steps, extraInfo } = props.data;

  return (
    <section id="online-therapy" className="pale">
      <Fade duration={1000}>
        <div className="row pricelist">
          <div className="three columns header-col">
            <h1>
              <span>{title}</span>
            </h1>
          </div>
          <div className="nine columns main-col">
              <p>{writeDescription(description)}</p>
              {writeSteps(steps)}
              {writeDescription(extraInfo)}
          </div>
        </div>
      </Fade>
    </section>
  );
};

export default OnlineTherapy;
