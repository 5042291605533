import * as React from 'react'

const writeDescription = (text) => {
    if (Array.isArray(text)) {
        return (<div>{text.map((t, idx) => (<p key={idx} className="justify">{t}</p>))}</div>)
    }

    return (<p className="justify">{text}</p>)
}

export default writeDescription