import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import appInfo from './appInfo.json';
import GA4React from 'ga-4-react';
import App from './App';
import reportWebVitals from './reportWebVitals';

// const dev2 = "G-PC5M1PSX6C";
const main = "G-WV8Q995BHF";

const googleAnalytics = new GA4React(main);

(async () => {
  await googleAnalytics.initialize()
    .then(res => console.log('Page loaded.'))
    .catch(err => console.log(err))
    .finally(() => {
      ReactDOM.render(
        <App version={appInfo.version} sha={appInfo.sha} name={appInfo.name} />,
        document.getElementById('root')
      );
    });
})()


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
