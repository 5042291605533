import * as React from "react";
import Slide from "react-reveal";
import writeDescription from '../helpers/writeDescription'


const Therapy = (props) => {
  if (!props.data) return null;
  const { 
    title, 
    subTitle1,
    description1,
    subTitle2,
    description2
  } = props.data;

  return (
    <section id="therapy" className="green">
      <Slide left duration={1300}>
        <div className="row therapy">
          <div className="three columns header-col">
            <h1>
              <span>{title}</span>
            </h1>
          </div>

          <div className="nine columns main-col">
            <div className="row item">
              <div className="twelve columns">
                <h2>{subTitle1}</h2>
                {writeDescription(description1)}
                <h2>{subTitle2}</h2>
                {writeDescription(description2)}
              </div>
            </div>
          </div>
        </div>
      </Slide>
    </section>
  );
};

export default Therapy;
