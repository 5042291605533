import * as React from "react";
import Slide from "react-reveal";

const Offer = (props) => {
  if (!props.data) return null;
  const {
    title,
    description
  } = props.data;

  return (
    <section id="offer" className="pale">
      <Slide left duration={1300}>
        <div className="row for-who">
          <div className="three columns header-col">
            <h1>
              <span>{title}</span>
            </h1>
          </div>

          <div className="nine columns main-col">
            <div className="row item">
              <div className="twelve columns">
                <p>{description.paragraph1}</p>
                <ul className="square">
                  {description.list1.map((txt, idx) => (
                    <li key={idx}>{txt}</li>
                  ))}
                </ul>
                <p>{description.paragraph2}</p>
                <ul className="square">
                  {description.list2.map((txt, idx) => (
                    <li key={idx}>{txt}</li>
                  ))}
                </ul>
                <p>{description.paragraph3}</p>
                <p>{description.price1.label} <strong>{description.price1.value}</strong></p>
                <p>{description.price2.label} <strong>{description.price2.value}</strong></p>
              </div>
            </div>
          </div>
        </div>
      </Slide>
    </section>
  );
};

export default Offer;
