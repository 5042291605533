import React from "react";
import Fade from "react-reveal";
import writeDescription from "../helpers/writeDescription";

const About = (props) => {
  if (!props.data) return null;

  const {
    title,
    description,
    photo,
    trainingsTitle,
    trainingsList,
    experienceTitle,
    experienceDescription,
    documentsTitle,
    documentsDescription,
    documents
  } = props.data;

  const renderDocs = documents.map((d, idx) => (
    <div key={idx} className="columns portfolio-item">
      <div className="item-wrap">
        <img style={{maxWidth: '100%'}} alt={d.title} src={d.src} />
      </div>
    </div>))

  return (
    <section id="about-me" className="green">
      <Fade duration={1000}>
        <div className="row">
          <div className="three columns">
            <img className="profile-pic" src={photo} alt="Profile Pic" />
          </div>
          <div className="nine columns main-col">
            <h2>{title}</h2>

            {writeDescription(description)}
            <div className="row">
              <div className="columns">
                <h2>{experienceTitle}</h2>
                {writeDescription(experienceDescription)}
              </div>
              <div className="columns">
                <h2>{trainingsTitle}</h2>
                <ul className="square">
                  {trainingsList.map((e, idx) => (
                    <li key={idx}>{e.name}</li>
                  ))}
                </ul>
              </div>
              <div className="columns">
                <h2>{documentsTitle}</h2>
                {writeDescription(documentsDescription)}
                <div
                  id="portfolio-wrapper"
                  className="bgrid-quarters s-bgrid-thirds cf"
                >
                  {renderDocs}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </section>
  );
};

export default About;
